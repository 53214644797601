import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import ConsentLink from "./ConsentLink";

const AddictLipGlowMakeupVoucher = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobilePhone: "",
    chkConsent: false,
    chkConsentMkt: false,
  });
  const [formErrors, setFormErrors] = useState({});
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const inputRefs = {
    firstName: useRef(null),
    lastName: useRef(null),
    mobilePhone: useRef(null),
    selectedFilter1: useRef(null),
    selectedFilter2: useRef(null),
  };
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [dupModalOpen, setDupModalOpen] = useState(false);
  const [inputErrModalOpen, setInputErrModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConfirmationClose = () => setConfirmationModalOpen(false);
  const handleErrorClose = () => setErrorModalOpen(false); // Close the modal
  const handleInputErrClose = () => setInputErrModalOpen(false);
  const handleConfirmationConfirm = async () => await handleSubmit();
  const handleDupClose = () => {
    setFormErrors({});
    setDupModalOpen(false);
  };

  // const [filterOptions1, setFilterOptions1] = useState([]);
  const [filterOptions2, setFilterOptions2] = useState([]);
  const [selectedFilter1, setSelectedFilter1] = useState("");
  const [selectedFilter2, setSelectedFilter2] = useState("");

  const [data, setData] = useState([]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Optional: Validate input fields as they change
    const errors = { ...formErrors };

    // Update form data based on input type
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    // Handle filter 1 change
    if (name === "selectedFilter1") {
      // Handle the logic for filter1 change
      setSelectedFilter1(value);
      setSelectedFilter2("");
      setFilterOptions2(
        data
          .filter(
            (item) => item.channel_id === parseInt(value, 10) && item.pos_name
          )
          .map((item) => ({ value: item.store_id, label: item.pos_name }))
      );

      // Update form errors for selectedFilter1
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        selectedFilter1: value ? "" : prevErrors.selectedFilter1,
      }));
    }

    // Handle other input changes with validation
    if (
      name !== "chkSMSMMS" &&
      name !== "chkConsent" &&
      name !== "chkConsentMkt"
    ) {
      if (!value) {
        errors[name] = "กรุณากรอกข้อมูล";
      } else {
        delete errors[name];
      }
    }

    if (name === "chkConsent") {
      if (!checked) {
        errors.chkConsent = "กรุณายอมรับเงื่อนไข";
      } else {
        delete errors.chkConsent;
      }
    }

    if (name === "chkConsentMkt") {
      if (!checked) {
        errors.chkConsentMkt = "กรุณายอมรับเงื่อนไข";
      } else {
        delete errors.chkConsentMkt;
      }
    }

    if (name === "mobilePhone") {
      const filteredValue = value.replace(/[^0-9]/g, "");
      if (
        (filteredValue.length < 10 || !filteredValue.startsWith("0")) &&
        filteredValue !== ""
      ) {
        errors.mobilePhone =
          "หมายเลขโทรศัพท์มือถือจะต้องขึ้นต้นด้วย 0 และมีความยาวอย่างน้อย 10 หลัก";
      } else if (!filteredValue) {
        errors.mobilePhone = "กรุณากรอกข้อมูล";
      } else {
        delete errors.mobilePhone;
      }
      // Update form data with filtered phone number
      setFormData((prevData) => ({
        ...prevData,
        [name]: filteredValue,
      }));
    }

    // Log and update form errors
    console.log(errors);
    setFormErrors(errors);
  };

  const handleConfirmClick = (e) => {
    e.preventDefault();

    const errors = {};

    // Check if selectedFilter1 is empty
    if (!selectedFilter1) {
      errors.selectedFilter1 = "กรุณาเลือกชื่อห้าง";
    }

    // Check if selectedFilter2 is empty
    if (!selectedFilter2) {
      errors.selectedFilter2 = "กรุณาเลือกสาขา";
    }

    // Check other form data for errors
    Object.keys(formData).forEach((key) => {
      if (key !== "chkConsent" && key !== "chkConsentMkt") {
        if (!formData[key]) {
          errors[key] = "กรุณากรอกข้อมูล";
        }
      } else if (key === "chkConsent" || key === "chkConsentMkt") {
        if (!formData[key]) {
          errors[key] = "กรุณายอมรับเงื่อนไข";
        }
      }
    });

    // Validate mobile phone number
    const mobilePattern = /^[0-9]+$/;
    if (formData.mobilePhone && !mobilePattern.test(formData.mobilePhone)) {
      errors.mobilePhone = "หมายเลขโทรศัพท์มือถือจะต้องเป็นตัวเลขเท่านั้น";
    } else if (
      formData.mobilePhone &&
      (formData.mobilePhone.length < 10 ||
        !formData.mobilePhone.startsWith("0"))
    ) {
      errors.mobilePhone =
        "หมายเลขโทรศัพท์มือถือจะต้องขึ้นต้นด้วย 0 และมีความยาว 10 หลัก";
    }

    // Check if there are no errors and show confirmation modal
    if (Object.keys(errors).length === 0) {
      // console.log("Submitted:", {
      //   selectedFilter1,
      //   selectedFilter2,
      //   ...formData,
      // });
      // alert("Form submitted successfully!");

      let posName = getPosNameByStoreId(selectedFilter2 * 1);
      let channelName = getChannelNameByChannelId(selectedFilter1 * 1);
      let phone = getPhoneNumberByStoreId(selectedFilter2 * 1)

      const cleanedNumber = formData.mobilePhone.replace(/\D/g, "");
      const transformedNumber = "66" + cleanedNumber.substring(1);

      setFormData((prevData) => ({
        ...prevData,
        ["selectedFilter2"]: selectedFilter2,
        ["store_name_th"]: posName,
        ["partner_name"]: channelName,
        ["store_phone_number"]: phone,
        ["userPhone66"]: transformedNumber
      }));

      console.log(formData);

      setConfirmationModalOpen(true);
    } else {
      // If there are errors, update form errors state
      setFormErrors(errors);
      setInputErrModalOpen(true); // Show error modal
    }
  };

  const filterOptions1 = Array.from(
    new Map(
      data
        .filter((item) => item.channel_id && item.channel_name)
        .map((item) => [
          item.channel_id,
          { value: item.channel_id, label: item.channel_name },
        ])
    ).values()
  );

  const getPosNameByStoreId = (storeId) => {
    let store = data.find((item) => item.store_id === storeId);
    return store ? store.pos_name : null; // Returns null if no matching store is found
  };

  const getChannelNameByChannelId = (channelId) => {
    let channel = data.find((item) => item.channel_id === channelId);
    return channel ? channel.channel_name : null; // Returns null if no matching channel is found
  };

  const getPhoneNumberByStoreId = (storeId) => {
    let store = data.find((item) => item.store_id === storeId);
    return store ? store.telephone : null; // Returns null if no matching channel is found
  };

  const handleSubmit = async () => {
    console.log("handleSubmit");

    setLoading(true);

    try {
      const response = await axios.post(
        `${apiUrl}/check-dup-and-insert-event`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          crossdomain: true,
        }
      );

      setConfirmationModalOpen(false);

      if (response.status === 201) {
        navigate("/AddictLipGlow/MakeupVoucher/Complete", {
          state: {
            // value: formData.storeNameTh
            // value: formData.eventSelect
          },
        });
      } else {
        setDupModalOpen(true);
      }
    } catch (error) {
      setConfirmationModalOpen(false);
      setErrorModalOpen(true); // Open the error modal

      console.error("API error:", error); // Optional: Log the error for debugging
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Fetch dropdown options from API
  const fetchDropdownOptions = async () => {
    setLoading(true); // Set loading state to true before fetching data
    try {
      const token = localStorage.getItem("token");

      // Fetch options for Filter 1
      const response1 = await axios.post(
        `${apiUrl}/getStoreAndChannel`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const sortedData = response1.data.storeAndChannel.sort(
        (a, b) => a.channel_id - b.channel_id
      );

      // Set sorted data
      setData(sortedData);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    } finally {
      setLoading(false); // Set loading state to false after data is fetched or an error occurs
    }
  };

  // useEffect hook to fetch data on component mount (or when selectedFilter1 changes)
  useEffect(() => {
    fetchDropdownOptions();
  }, []); // Empty array means it runs once on mount. Add selectedFilter1 if needed in the dependency array

  return (
    <div>
      <Container style={{ margin: "30px auto", padding: "24px 24px" }}>
        <Form>
          <FormGroup>
            <Label for="titelCp">ลงทะเบียนรับ Lip Glow Makeup Voucher</Label>
          </FormGroup>
          <FormGroup>
            <Label for="firstName">ชื่อ First name</Label>
            <Input
              id="firstName"
              name="firstName"
              type="text"
              value={formData.firstName}
              onChange={handleInputChange}
              invalid={!!formErrors.firstName}
              innerRef={inputRefs.firstName}
            />
            <FormFeedback>{formErrors.firstName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="lastName">นามสกุล Last name</Label>
            <Input
              id="lastName"
              name="lastName"
              type="text"
              value={formData.lastName}
              onChange={handleInputChange}
              invalid={!!formErrors.lastName}
              innerRef={inputRefs.lastName}
            />
            <FormFeedback>{formErrors.lastName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="mobilePhone">โทรศัพท์มือถือ Mobile phone</Label>
            <Input
              id="mobilePhone"
              name="mobilePhone"
              type="text" // Use 'text' to manually control validation and length
              value={formData.mobilePhone}
              onChange={handleInputChange}
              invalid={!!formErrors.mobilePhone}
              // maxLength="20" // Enforces the length constraint in the UI
              maxLength="10" // Enforces the length constraint in the UI
              innerRef={inputRefs.mobilePhone}
            />
            <FormFeedback>{formErrors.mobilePhone}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="selectedFilter1">ชื่อห้าง</Label>
            <Input
              id="selectedFilter1"
              name="selectedFilter1"
              type="select"
              value={selectedFilter1}
              invalid={!!formErrors.selectedFilter1}
              innerRef={inputRefs.selectedFilter1}
              onChange={handleInputChange}
            >
              <option value="">เลือกชื่อห้าง</option>
              {filterOptions1.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Input>
            <FormFeedback>{formErrors.selectedFilter1}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="selectedFilter2">สาขา</Label>
            <Input
              id="selectedFilter2"
              name="selectedFilter2"
              type="select"
              value={selectedFilter2}
              invalid={!!formErrors.selectedFilter2}
              innerRef={inputRefs.selectedFilter2}
              onChange={(e) => {
                const value = e.target.value;
                setSelectedFilter2(value);
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  selectedFilter2: value ? "" : prevErrors.selectedFilter2,
                }));
              }}
            >
              <option value="">เลือกสาขา</option>
              {filterOptions2.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Input>
            <FormFeedback>{formErrors.selectedFilter2}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>
              <Input
                type="checkbox"
                id="chkConsent"
                name="chkConsent"
                checked={formData.chkConsent}
                onChange={handleInputChange}
                style={{
                  transform: "scale(1.15)",
                  border: "2px solid",
                  borderRadius: "3px",
                  backgroundColor: formData.chkConsent ? "black" : "white",
                  boxShadow: "none",
                }}
              />
              &nbsp;&nbsp; ฉันได้อ่าน,
              ทำความเข้าใจและยอมรับเงื่อนไขสำหรับนโยบายความเป็นส่วนตัว (
              <ConsentLink />)
              {formErrors.chkConsent && (
                <FormFeedback className="d-block">
                  {formErrors.chkConsent}
                </FormFeedback>
              )}
            </Label>
          </FormGroup>
          <FormGroup>
            <Label>
              <Input
                type="checkbox"
                id="chkConsentMkt"
                name="chkConsentMkt"
                checked={formData.chkConsentMkt}
                onChange={handleInputChange}
                style={{
                  transform: "scale(1.15)",
                  border: "2px solid",
                  borderRadius: "3px",
                  backgroundColor: formData.chkConsentMkt ? "black" : "white",
                  boxShadow: "none",
                }}
              />
              &nbsp;&nbsp; ฉันได้อ่าน,
              ทำความเข้าใจและตกลงที่จะรับการสื่อสารการตลาดและการบริการ
            </Label>
            {formErrors.chkConsentMkt && (
              <FormFeedback className="d-block">
                {formErrors.chkConsentMkt}
              </FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label>
              หมายเหตุ
              <br />
              ข้อมูลทั้งหมดที่ท่านให้จะได้รับการเก็บรักษาไว้เป็นความลับ
            </Label>
          </FormGroup>
        </Form>
        <div style={{ textAlign: "center" }}>
          <br />
          <Button
            style={{
              width: "100%",
              maxWidth: "200px",
              backgroundColor: "#000",
              borderColor: "#000",
              color: "#FFF",
              borderRadius: "1px",
            }}
            onClick={handleConfirmClick}
          >
            ยืนยัน Confirm
          </Button>
        </div>

        {/* Confirmation Modal */}
        <Modal isOpen={confirmationModalOpen} toggle={handleConfirmationClose}>
          <ModalHeader toggle={handleConfirmationClose}>
            ยืนยันการส่งข้อมูล
          </ModalHeader>
          <ModalBody>
            คุณต้องการยืนยันข้อมูลและส่งข้อมูลนี้หรือไม่?
            <br />
            Do you want to confirm and submit this information?
          </ModalBody>
          <ModalFooter>
            {loading ? (
              <Button hidden>ยกเลิก</Button>
            ) : (
              <Button
                style={{
                  backgroundColor: "#000",
                  borderColor: "#000",
                  color: "#FFF",
                  borderRadius: "1px",
                }}
                onClick={handleConfirmationClose}
              >
                ยกเลิก
              </Button>
            )}
            {loading ? (
              <Spinner color="secondary" />
            ) : (
              <Button
                style={{
                  backgroundColor: "#000",
                  borderColor: "#000",
                  color: "#FFF",
                  borderRadius: "1px",
                }}
                onClick={handleConfirmationConfirm}
              >
                ยืนยัน
              </Button>
            )}
          </ModalFooter>
        </Modal>

        {/* Error Modal */}
        <Modal isOpen={errorModalOpen} toggle={handleErrorClose}>
          <ModalHeader toggle={handleErrorClose}>
            มีปัญหาในการเชื่อมต่อ
          </ModalHeader>
          <ModalBody>
            ระบบมีปัญหาในการส่งข้อมูล กรุณาลองอีกครั้งในภายหลัง
            <br />
            There was an issue submitting your data. Please try again later.
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                backgroundColor: "#000",
                borderColor: "#000",
                color: "#FFF",
                borderRadius: "1px",
              }}
              onClick={handleErrorClose}
            >
              ตกลง
            </Button>
          </ModalFooter>
        </Modal>

        {/* Dup Modal */}
        <Modal isOpen={dupModalOpen} toggle={handleDupClose}>
          <ModalHeader toggle={handleDupClose}>แจ้งเตือน</ModalHeader>
          <ModalBody>
            หมายเลขโทรศัพท์หรืออีเมลถูกใช้ลงทะเบียนไปแล้ว
            <br />
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                backgroundColor: "#000",
                borderColor: "#000",
                color: "#FFF",
                borderRadius: "1px",
              }}
              onClick={handleDupClose}
            >
              ตกลง
            </Button>
          </ModalFooter>
        </Modal>

        {/* Dup Modal */}
        <Modal isOpen={inputErrModalOpen} toggle={handleInputErrClose}>
          <ModalHeader toggle={handleInputErrClose}>แจ้งเตือน</ModalHeader>
          <ModalBody>
            กรุณากรอกข้อมูลให้ครบถ้วน
            <br />
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                backgroundColor: "#000",
                borderColor: "#000",
                color: "#FFF",
                borderRadius: "1px",
              }}
              onClick={handleInputErrClose}
            >
              ตกลง
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </div>
  );
};

export default AddictLipGlowMakeupVoucher;
