import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, NavLink, NavItem, Nav, Table,Button, Input, Label  } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import eventImage from "./images/logo-dior_mobile_share.jpg"; // Adjust the path as necessary
import "./DashboardPage.css";
import LineChart from "./LineChart";
import TableDashboardCampignDeatail from "./TableDashboardCampignDeatail";
import ExportExcel from './ExportExcel';
import { useParams } from 'react-router-dom';
import { TbCloudDownload } from "react-icons/tb";


const DashboardDetailRedeemPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [totalUser, setTotalUser] = useState([]);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [tableCampaignDeatail, setTableCampaignDeatail] = useState([]);
  const { campaign_id, campaign_name } = useParams();
  const [campaignName, setCampaignName] = useState(null);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Dropdown Options State
  // const [filterOptions1, setFilterOptions1] = useState([]);
  const [filterOptions2, setFilterOptions2] = useState([]);
  const [selectedFilter1, setSelectedFilter1] = useState("");
  const [selectedFilter2, setSelectedFilter2] = useState("");
// Fetch campaign data with filters
const fetchData = async () => {
  try {
    const token = localStorage.getItem('token'); // Retrieve JWT token

    const response = await axios.post(
      `${apiUrl}/dashboardBycampaignIdStorePartner`,
      {
        campaign_id: campaign_id,
        partner_id: selectedFilter1,    // Added store_id
        store_id: selectedFilter2    // Added partner_id
      },
      {
        headers: {
          'Authorization': `Bearer ${token}` // Send JWT token in header
        },
        crossdomain: true,
      }
    );

    const data = response.data;
    const registrations = data.last7Days;

    // Prepare chart data
    const labels = registrations.map((item) => item.date);
    const registrationCounts = registrations.map((item) => item.count);
    setChartData({
      labels,
      datasets: [
        {
          label: "Registrations per Day",
          data: registrationCounts,
          fill: false,
          borderColor: "black",
          tension: 0,
        },
      ],
    });

    setTotalUser(data.activeRegistrations.count);
    setTableCampaignDeatail(data.registrationDetails);

    // Set the formatted file name for export
    const now = new Date();
    const formattedDate = `${now.getFullYear()}_${String(now.getMonth() + 1).padStart(2, '0')}_${String(now.getDate()).padStart(2, '0')}`;
    setCampaignName(`report_${campaign_name}_${formattedDate}`);
    
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {
  fetchData();
  fetchDropdownOptions();
}, [campaign_id]); // Add selectedFilter1 to dependency array

// useEffect(() => {
//   fetchDropdownOptions();
// }, [selectedFilter1]); // Add selectedFilter1 to dependency array

// Handle Search Action
const handleSearch = () => {
  fetchData(); // Call fetchData with selected filters
};


// Handle clear action
const handleClear = () => {
  setSelectedFilter1("");
  setSelectedFilter2("");
  setFilterOptions2([]); // Clear partner options on clear
};

// useEffect to watch for both filters being cleared, then call fetchData
useEffect(() => {
  if (selectedFilter1 === "" && selectedFilter2 === "") {
    fetchData(); // Only calls when both are empty
  }
}, [selectedFilter1, selectedFilter2]);

const handleInputChange = (e) => {
  const { name, value, type, checked } = e.target;

  if (name === "selectedFilter1") {
    // Handle the logic for filter1 change
    setSelectedFilter1(value);
    setSelectedFilter2("");
    setFilterOptions2(
      data
        .filter(
          (item) => item.channel_id === parseInt(value, 10) && item.pos_name
        )
        .map((item) => ({ value: item.store_id, label: item.pos_name }))
    );

  }

};


  // Fetch dropdown options from API
  const fetchDropdownOptions = async () => {
    setLoading(true); // Set loading state to true before fetching data
    try {
      const token = localStorage.getItem("token");

      // Fetch options for Filter 1
      const response1 = await axios.post(
        `${apiUrl}/getStoreAndChannel`,
        {campaign_id: campaign_id,},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const sortedData = response1.data.storeAndChannel.sort(
        (a, b) => a.channel_id - b.channel_id
      );

      console.log(sortedData);
      
      // Set sorted data
      setData(sortedData);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    } finally {
      setLoading(false); // Set loading state to false after data is fetched or an error occurs
    }
  };

  const filterOptions1 = Array.from(
    new Map(
      data
        .filter((item) => item.channel_id && item.channel_name)
        .map((item) => [
          item.channel_id,
          { value: item.channel_id, label: item.channel_name },
        ])
    ).values()
  );
  return (
    <div>
      <Container fluid>
        <Row>
        <Col xs="12" md="2" style={{ padding: "0" , background:"black",minHeight: "95vh" }}>
        <div className="text-center">
              <img
                src={eventImage}
                alt="Event"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>
            <Nav vertical className="p-3">
              <NavItem>
                <NavLink href="/dashboard" className="text-white">
                  Back
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs="12" md="10" className="content">
          {/* <h4 style={{margin:"0px 0px 16px 20px"}}>Campaign</h4>
          <p style={{margin :"0px 0px 16px 20px"}} >{campaign_name}</p> */}
          <h4 style={{margin:"0px 0px 16px 20px"}}>Campaign: {campaign_name}</h4>

          <Row style={{ marginBottom: "16px" ,margin:"0px 0px 16px 5px"}}>
              <Col xs="6" md="3">
              <Label for="selectedFilter1">ชื่อห้าง</Label>
                <Input
                  id="selectedFilter1"
                  name="selectedFilter1"
                  type="select"
                  value={selectedFilter1}
                  onChange={handleInputChange}
                >
                  <option value="">เลือกชื่อห้าง</option>
                  {filterOptions1.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col xs="6" md="3">
              <Label for="selectedFilter2">สาขา</Label>
            <Input
              id="selectedFilter2"
              name="selectedFilter2"
              type="select"
              value={selectedFilter2}
              onChange={(e) => {
                const value = e.target.value;
                setSelectedFilter2(value);
                
              }}
            >
              <option value="">เลือกสาขา</option>
              {filterOptions2.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Input>
              </Col>
              <Col xs="12" md="4" style={{margin:'22px'}}>
                <Button color="primary" onClick={handleSearch}>Search</Button> &nbsp;&nbsp;
                <Button color="secondary" onClick={handleClear}>Clear</Button>
              </Col>
            </Row>
            <Row style={{marginTop:"-22px"}}>
              <Col xs="12" sm="6" lg="6" className="box">
              <div className="box-content-1">
                <LineChart data={chartData} />
                </div>
              </Col>
              <Col xs="12" sm="6" lg="6" className="box">
                <div className="box-content-3">
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  Total User Registered
                  <br></br>
                  <br></br>
                  <h1>{totalUser}</h1>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="12" lg="9" className="box" style={{margin:"0px 0px 16px 20px"}}>
                <p>Customer List</p>
              </Col>
              <Col xs="12" sm="12" lg="2" className="box" >
              <ExportExcel data={tableCampaignDeatail} fileName={campaignName} />
              </Col>
            </Row>

            <Row style={{margin:"-29px -10px -5px -9px"}}>
              <TableDashboardCampignDeatail campaigns={tableCampaignDeatail} />
            </Row>
          </Col>
        </Row>
      </Container> 
    </div>
    
  );
};

export default DashboardDetailRedeemPage;
