import React, { useState, useRef, useEffect } from "react";
import { Button, Container } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import eventImage from "./images/christian-dior-new3874.jpg";

const AddictLipGlowMakeupVoucherWelcome = () => {
  const location = useLocation();
  const { value } = location.state || {};
  const [store, setStore] = React.useState("");
  const navigate = useNavigate();

  const handleUpdate = () => {
    navigate(`/AddictLipGlow/MakeupVoucher/registration`);
  };

  useEffect(() => {
    if (value) {
      setStore(value);
    }
  }, []);

  return (
    <div>
      {/* <div className="text-center">
        <img
          src={eventImage}
          alt="Event"
          // className="img-fluid mb-4"
          style={{ width: "100%", height: "auto", maxWidth: "700px" }}
        />
      </div> */}
      <Container
        style={{
          textAlign: "center",
          margin: "30px auto",
          padding: "24px 24px",
        }}
      >
        <p style={{ textAlign: "center" }}>Thank you for your interest!</p>
        <br />
        <p style={{ textAlign: "center" }}>
          ดิออร์ขอมอบสิทธิพิเศษสำหรับลูกค้า Babycakes เมื่อซื้อ Limited-edition
          Heart Shape Cake รับ Dior Makeup Voucher เพื่อร่วมต้อนรับ Dior Addict
          Lip Glow ไอเทมเมค อัพที่ทุกท่านหลงรัก มาพร้อมสูตรใหม่ล่าสุด
          เพิ่มความชุ่มชื้นยาวนานมากขึ้น ด้วยส่วนผสม จากธรรมชาติสูงถึง 97%
          ในแพคเกจจิ้งใหม่ที่เพิ่มความกูตูร์มากกว่าเคย พบกับ 21 เฉดสี
          ใหม่ล่าสุดในโทร Cool และ Warmที่ช่วยปรับริมฝีปากให้สว่าง สดใส
          ไม่หมองคล้ำ
        </p>
        <br />

        <p style={{ textAlign: "center" }}>
          สำหรับลูกค้า Babycakes เท่านั้น ดิออร์ขอมอบ Touch-up Service ในลุค Get
          The Glow และทดลอดเฉดสีใหม่เพื่อค้นหาสีที่ใช่สำหรับคุณ
        </p>
        <br />
        <br />
        <Button
          onClick={handleUpdate}
          style={{
            width: "100%",
            maxWidth: "235px",
            backgroundColor: "#000",
            borderColor: "#000",
            color: "#FFF",
            borderRadius: "1px",
          }}
        >
          ลงทะเบียน Register
        </Button>
      </Container>
    </div>
  );
};

export default AddictLipGlowMakeupVoucherWelcome;
