import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router-dom";
import imgLipsP1 from "./images/HPPAddictLipGlowSiamParagonTMGCOVERPUSH1Crop.jpg"; // Adjust the path as necessary
import imgLipsP2 from "./images/HPPAddictLipGlowSiamParagonTMGCOVERPUSH2.jpg"; // Adjust the path as necessary
import imgLipsP3 from "./images/HPPAddictLipGlowSiamParagonTMGCOVERPUSH3Crop.jpg"; // Adjust the path as necessary

const ConfirmPageAddictLipGlowRenoSiamParagonTMG = () => {
  const location = useLocation();
  const { value } = location.state || {};
  const [date, setDate] = React.useState("");

  useEffect(() => {
    // console.log(value);
    if (value) {
      const [year, month, day] = value.split('-');
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      
      // If day is less than 10, remove the leading zero
      const dayFormatted = parseInt(day, 10);
      
      const formattedDate = `${dayFormatted} ${months[parseInt(month) - 1]} ${year}`;
      setDate(formattedDate);
    }
  }, [value]);

  return (
    <div style={{paddingTop : "25px"}}> 
      <div className="container">
       
          <p style={{ textAlign: "center" }}>
            ขอบคุณสำหรับการลงทะเบียนเพื่อเข้าร่วมกิจกรรม <br></br>Dior Addict Lip Glow Reno
            <br />
          </p>
          <br />
          <p style={{ textAlign: "center", fontWeight: "bold" }}>วันที่ {date}</p>
          <br />
          <p style={{ textAlign: "center" }}>
          สัมผัสไอเทมที่ทุกท่านหลงรักจากดิออร์ Dior Addict Lip Glow และร่วมสนุกไปการค้นหาเฉดสีที่บ่งบอกถึงตัวตนของคุณจาก 21 เฉดสีใหม่ล่าสุดที่มาพร้อมโทนสีเย็นสำหรับผิวอมชมพูและโทนสีอุ่น สำหรับผิวอมเหลือง และสูตรใหม่ล่าสุดที่มอบความชุ่มชื้นยาวนานมากขึ้นจาก Moisture Lock Technology มอบริมฝีปากอิ่มฟู และสีสันจาก pH-Activation Technology เพื่อสีสันสวยระเรื่อยาวนานตลอดทั้งวัน
          </p>
          <p style={{ textAlign: "center" }}>
          พิเศษ รับ Forever Skin Glow Fluid Foundation Sample
            ระหว่างวันที่ 6 – 19 ม.ค. 68
            <br></br>
            เวลา 11:00 - 20:00 น.
            <br></br>
            ณ พื้นที่ POP UP M ชั้น M ศูนย์การค้าสยามพารากอน
          </p>
          <p style={{ textAlign: "center" }}>
            รอรับข้อความ SMS
            และแสดงข้อความที่ได้รับที่จุดลงทะเบียนเพื่อรับสิทธิพิเศษภายในงาน{" "}
          </p>
          <p style={{ textAlign: "center" }}>
            *รับผลิตภัณฑ์ขนาดทดลองหลังรับบริการ 
            <br></br>จำนวนจำกัด 1ท่าน : 1สิทธิ์
          </p>
        </div>
        <div style={{display: "flex", maxWidth: "100%",justifyContent: "center"}}>
        <div style={{maxWidth: "215px"}}>
          <img
            src={imgLipsP1}
            alt="Event"
            // className="img-fluid mb-4"
            style={{ width: "100%", height: "auto", maxWidth: "none" }}
          />
        </div>
        <div style={{maxWidth: "215px"}}>
          <img
            src={imgLipsP2}
            alt="Event"
            // className="img-fluid mb-4"
            style={{ width: "100%", height: "auto", maxWidth: "none" }}
          />
        </div><div style={{maxWidth: "215px"}}>
          <img
            src={imgLipsP3}
            alt="Event"
            // className="img-fluid mb-4"
            style={{ width: "100%", height: "auto", maxWidth: "none" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmPageAddictLipGlowRenoSiamParagonTMG;
