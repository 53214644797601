import React from "react";
import { Row, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';
import moment from 'moment';

const TableDashboardCampign = ({ campaigns }) => {
  const columns = [
    {
      name: 'Name',
      selector: row => row.campaign_name,
      sortable: true,
      cell: row => (
        row.campaign_id === 878 
          ? (
        <a 
          href={`/dashboardDetailVoucher/${row.campaign_id}/${row.campaign_name}`} 
          style={{ color: 'black'}}
        >
          {row.campaign_name}
        </a>
        ) : (<a 
          href={`/dashboardDetail/${row.campaign_id}/${row.campaign_name}`} 
          style={{ color: 'black'}}
        >
          {row.campaign_name}
        </a>
      )
    )
    },
    {
      name: 'Start Date',
      selector: row => row.start_date,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = moment(rowA.start_date);
        const dateB = moment(rowB.start_date);
        
        if (dateA.isBefore(dateB)) return -1;
        if (dateA.isAfter(dateB)) return 1;
        return 0;
      }
    },
    {
      name: 'End Date',
      selector: row => row.end_date,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = moment(rowA.end_date);
        const dateB = moment(rowB.end_date);
        
        if (dateA.isBefore(dateB)) return -1;
        if (dateA.isAfter(dateB)) return 1;
        return 0;
      }
    },
    {
      name: 'Status',
      selector: row => row.campaign_status,
      sortable: true,
    },
    {
      name: 'Total Registered',
      selector: row => row.total_regis,
      sortable: true,
    }
  ];

  return (
    // <Row>
      <Col xs="12" sm="12" lg="12" className="box">
        <div className="box-content-tb">
          <DataTable
            columns={columns}
            data={campaigns}
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 20, 30]}
            striped
            highlightOnHover
            noHeader
          />
        </div>
      </Col>
    // </Row>
  );
};

export default TableDashboardCampign;