import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router-dom";
import imgLipsP1 from "./images/M013_RENO_LIP_GLOW_25_P11G_FOCUS_LOGO_DIOR_L4_F39.jpg"; // Adjust the path as necessary
import imgLipsP2 from "./images/M013_RENO_LIP_GLOW_25_P05i_HERO_DUO_teintes004_001_L4_F39.jpg"; // Adjust the path as necessary
import imgLipsP3 from "./images/M013_RENO_LIP_GLOW_25_P10H_FOCUS_RAISIN_SW_F39.jpg"; // Adjust the path as necessary

const ConfirmPageSiamParagonCU = () => {
  const location = useLocation();
  const { value } = location.state || {};
  const [date, setDate] = React.useState("");

  useEffect(() => {
    // console.log(value);
    if (value) {
      const [year, month, day] = value.split('-');
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      
      // If day is less than 10, remove the leading zero
      const dayFormatted = parseInt(day, 10);
      
      const formattedDate = `${dayFormatted} ${months[parseInt(month) - 1]} ${year}`;
      setDate(formattedDate);
    }
  }, [value]);

  return (
    <div style={{paddingTop : "25px"}}> 
      <div className="container">
       
          <p style={{ textAlign: "center" }}>
            ขอบคุณสำหรับการลงทะเบียนเพื่อเข้าร่วมกิจกรรม <br></br>Dior Addict Lip Glow Reno
            <br />
          </p>
          <br />
          <p style={{ textAlign: "center", fontWeight: "bold" }}>วันที่ {date}</p>
          <br />
          <p style={{ textAlign: "center" }}>
            ดิออร์ ชวนคุณพบกัน Lip Glow ใหม่ล่าสุด ไอเทมเมคอัพในตำนานจากดิออร์ที่มอบริมฝีปากอิ่มฟูชมพูระเรื่อ ด้วย pH-Activation Technology ด้วยสูตรใหม่เพิ่มความชุ่มชื้นยาวนานมากขึ้นด้วยส่วนผสมบำรุงที่เข้มข้นจากธรรมชาติ 97% ด้วยเฉด 21 เฉดสีใหม่ล่าสุดในโทน Cool & Warm เพื่อช่วยปรับสีริมฝีปากให้สวยอย่างเป็นธรรมชาติ ไม่หมองคล้ำ
          </p>
          <p style={{ textAlign: "center" }}>
          พิเศษสำหรับนิสิตจุฬาลงกรณ์มหาวิทยาลัย รับบริการ Touch up Service ในลุค
          </p>
          <p style={{ textAlign: "center" }}>
          “Get The Glow” พร้อมทดลองเฉดสีใหม่ และ รับของขวัญสุดพิเศษจากดิออร์
          </p>
          <p style={{ textAlign: "center" }}>
            ระหว่างวันที่ 6 – 19 ม.ค. 68
            <br></br>
            เวลา 11:00 - 20:00 น.
            <br></br>
            ณ พื้นที่ POP UP M ชั้น M ศูนย์การค้าสยามพารากอน
          </p>
          <p style={{ textAlign: "center" }}>
            รอรับข้อความ SMS
            และแสดงข้อความที่ได้รับที่จุดลงทะเบียนเพื่อรับสิทธิพิเศษภายในงาน{" "}
          </p>
          <p style={{ textAlign: "center" }}>
            *รับผลิตภัณฑ์ขนาดทดลองหลังรับบริการ 
            <br></br>จำนวนจำกัด 1 ท่าน : 1 สิทธิ์
          </p>
        </div>
        <div style={{display: "flex", maxWidth: "100%",justifyContent: "center"}}>
        <div style={{maxWidth: "215px"}}>
          <img
            src={imgLipsP1}
            alt="Event"
            // className="img-fluid mb-4"
            style={{ width: "100%", height: "auto", maxWidth: "none" }}
          />
        </div>
        <div style={{maxWidth: "215px"}}>
          <img
            src={imgLipsP2}
            alt="Event"
            // className="img-fluid mb-4"
            style={{ width: "100%", height: "auto", maxWidth: "none" }}
          />
        </div><div style={{maxWidth: "215px"}}>
          <img
            src={imgLipsP3}
            alt="Event"
            // className="img-fluid mb-4"
            style={{ width: "100%", height: "auto", maxWidth: "none" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmPageSiamParagonCU;
