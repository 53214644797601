import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router-dom";
import img1 from "./images/AddictLipGlow_PUSH1.jpg"; // Adjust the path as necessary
import img2 from "./images/AddictLipGlow_PUSH2.jpg"; // Adjust the path as necessary
import img3 from "./images/AddictLipGlow_PUSH3.jpg"; // Adjust the path as necessary
import eventImage from "./images/dior_logo_ex.png"; // Adjust the path as necessary

const AddictLipGlowMakeupVoucherComplete = () => {
  const location = useLocation();
  const { value } = location.state || {};
  const [date, setDate] = React.useState("");

  useEffect(() => {
    // console.log(value);
    if (value) {
      const [year, month, day] = value.split("-");
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // If day is less than 10, remove the leading zero
      const dayFormatted = parseInt(day, 10);

      const formattedDate = `${dayFormatted} ${
        months[parseInt(month) - 1]
      } ${year}`;
      setDate(formattedDate);
    }
  }, [value]);

  return (
    <div>
      <div
        className="container"
        style={{ margin: "30px auto", padding: "24px 24px" }}
      >
        <p style={{ textAlign: "center" }}>
          ขอบคุณสำหรับการลงทะเบียนเพื่อรับ
          <br />
          Lip Glow Makeup Voucher
        </p>
        <br />
        <p style={{ textAlign: "center" }}>
          ระหว่างวันที่ 15 ม.ค. – 30 เม.ย. 68
          <br />
          เวลา 10.00 – 20.00 น.
        </p>
        <p style={{ textAlign: "center" }}>
          กรุณารอรับข้อความ SMS เพื่อยืนยันการรับสิทธิ์และแสดง voucher
          ที่เคาน์เตอร์เครื่องสำอางดิออร์ หรือรับบริการที่ HPP Lip Glow
          ตามตารางข้างนี้
        </p>
        <p style={{ textAlign: "center" }}>
          SIAM PARAGON (POP UP M) วันที่ 6-19 ม.ค. 2568
          <br />
          ICONSIAM (THARA HALL) วันที่ 21-31 ม.ค. 2568
          <br />
          MEGA BANGNA (CDS) วันที่ 6-19 ก.พ. 2568
          <br />
          CENTRAL LADPRAO (PRO AREA X2) วันที่ 13 – 26 ก.พ. 2568
        </p>
        <p style={{ textAlign: "center" }}>*จำนวนจำกัด 1ท่าน : 1สิทธิ์</p>
      </div>
      <div
        style={{ display: "flex", maxWidth: "100%", justifyContent: "center" }}
      >
        <div style={{ maxWidth: "215px" }}>
          <img
            src={img1}
            alt="Event"
            // className="img-fluid mb-4"
            style={{ width: "100%", height: "auto", maxWidth: "none" }}
          />
        </div>
        <div style={{ maxWidth: "215px" }}>
          <img
            src={img2}
            alt="Event"
            // className="img-fluid mb-4"
            style={{ width: "100%", height: "auto", maxWidth: "none" }}
          />
        </div>
        <div style={{ maxWidth: "215px" }}>
          <img
            src={img3}
            alt="Event"
            // className="img-fluid mb-4"
            style={{ width: "100%", height: "auto", maxWidth: "none" }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddictLipGlowMakeupVoucherComplete;
